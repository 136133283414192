<template>
  <div class="about">
    <h1>受発注入力</h1>
    <v-card class="pa-5" flat outlined>
      <v-form ref="registForm">
        <v-autocomplete
          v-model="selectDenpyo"
          :items="denpyoDetails"
          outlined
          label="伝票番号"
          :item-text="item => item.code + ' ' + item.name"
          item-value="code"
          chips
          :rules="[selected]"
        >
        </v-autocomplete>
        <DatePicker
          v-model="selectDate"
          label="伝票日付"
          prepend-inner-icon="mdi-calendar"
          type="day"
          outlined
          :rules="[selected]"
        />
        <v-autocomplete
          v-model="selectCustomer"
          :items="customerDetails"
          outlined
          label="得意先"
          :item-text="item => item.code + ' ' + item.name"
          item-value="code"
          chips
          :rules="[selected]"
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="selectCustomerGroup"
          :items="customerGroupDetails"
          outlined
          label="得意先グループ"
          :item-text="item => item.code + ' ' + item.name"
          item-value="code"
          chips
          :rules="[selected]"
        >
        </v-autocomplete>
        <v-autocomplete
          v-model="selectFormat"
          :items="formatDetails"
          outlined
          label="フォーマット"
          :item-text="item => item.code + ' ' + item.name"
          item-value="code"
          chips
          :rules="[selected]"
        >
        </v-autocomplete>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <Btn icon="send" color="success" @click="get('')">取得</Btn>
      </v-card-actions>
      <v-form ref="registForm2" style="margin-top: 50px">
        <v-text-field
          v-model="inputTekiyou"
          prepend-inner-icon="mdi-text"
          label="摘要"
          outlined
        />
      </v-form>
    </v-card>
    <v-card class="">
      <table border="1">
        <thead>
          <tr>
            <th v-for="(cal, i) in tableHead" :key="i">
              {{ cal }}
            </th>
          </tr>
        </thead>
        <template v-for="(tempTD, j) in tableData">
          <tr :key="j">
            <template v-for="(val, k) in tempTD">
              <td :key="k" :title="k">
                {{ val }}
              </td>
            </template>
          </tr>
        </template>
      </table>
    </v-card>
  </div>
</template>
<script>
// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
// VueGoodTable
import "vue-good-table/dist/vue-good-table.css";

import Api from "@/mixins/api";

const DATE = new Date();
const reg = new RegExp(/^[0-9]+$/);

export default {
  name: "JyutyuInput",
  mixins: [Common, ShowDialogs, Api],
  components: {},
  data: () => ({
    // セレクト（ダミー）
    denpyoDetails: [
      { code: "001", name: "伝票㋐" },
      { code: "002", name: "伝票㋑" },
      { code: "003", name: "伝票㋒" }
    ],
    customerDetails: [
      { code: "001", name: "得意先1" },
      { code: "002", name: "得意先2" },
      { code: "003", name: "得意先3" }
    ],
    customerGroupDetails: [
      { code: "001", name: "得意先G1" },
      { code: "002", name: "得意先G2" },
      { code: "003", name: "得意先G3" }
    ],
    formatDetails: [
      { code: "001", name: "フォーマットA" },
      { code: "002", name: "フォーマットB" },
      { code: "003", name: "フォーマットC" }
    ],
    selectDenpyo: "",
    selectDate:
      DATE.getFullYear() +
      "-" +
      ("00" + Number(DATE.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + Number(DATE.getDate())).slice(-2),
    selectCustomer: "",
    selectCustomerGroup: "",
    selectFormat: "",
    // インプット（ダミー）
    inputTekiyou: "ここに文字を入力",
    // テーブル（ダミー）
    tableHead: [
      "産地",
      "品名",
      "規格",
      "入数",
      "発注数",
      "単位",
      "実数",
      "単価",
      "備考"
    ],
    tableData: [
      {
        santi: "フィリピン",
        name: "バナナ",
        kikaku: "001",
        irisuu: "19",
        hattyusuu: "1",
        tani: "C/S",
        jissuu: "",
        tanka: "",
        bikou: ""
      },
      {
        santi: "フィリピソ",
        name: "バナナではない",
        kikaku: "002",
        irisuu: "8",
        hattyusuu: "",
        tani: "",
        jissuu: "",
        tanka: "",
        bikou: ""
      }
    ],
    tableDispData: [],
    // 入力規則
    selected: value => !!value || "選択してください", // 選択必須の制約
    required: value => !!value || "入力してください", // 入力必須の制約
    numeric: value => reg.test(value) || "数字を入力してください"
  }),
  computed: {},
  methods: {
    async get() {
      try {
        console.log("get", "開始");
        const customerCd = this.selectCustomer;
        const formatCd = this.selectFormat;
        const nameCd = this.selectName;
        console.log("選択された得意先", customerCd);
        console.log("選択されたフォーマット", formatCd);
        console.log("選択された名称", nameCd);

        if (this.$refs.registForm.validate()) {
          // すべてのバリデーションが通過したときのみ if文の中に入る
          console.log("submit", "success");
          alert("取得ダミー : 成功!");
        } else {
          console.log("submit", "failed");
          alert("取得ダミー : 失敗!");
        }
      } catch (e) {
        this.$error(e.message);
      } finally {
        this.$unloading();
      }
    }
  },
  mounted() {
    console.log("mounted");
  },
  async created() {
    console.log("created");
  }
};
</script>
<style lang="scss" scoped></style>
